import React, { useEffect, useState, useContext } from "react";
import { Moneyformat, Cardstructure, post, sendTrack } from "../Generals";
import { slugify } from "./../utils/string";
import Loader from './../Components/Loader';

import GeneralContext from "../utils/generalContext";
import {Row, Col} from "react-bootstrap";
import { useParams } from "react-router";
import { useQuery, useMutation } from '@apollo/client';
import { CREATE_CUSTOMER, CREATE_INVOICE, CREATE_PAYMENT_CONCEPT, GET_INVOICE, GET_PAYMENT_CONCEPT, GET_CUSTOMER, GET_CUSTOM_DOMAIN, CREATE_PAYMENT_LINK } from "./helpers/pay_order";

export const PayOrder = () => {
    const [ content, setContent ]=useState(null)
    const [ producto, setProducto ]=useState()
    const { global, user } = useContext(GeneralContext)
    const userid = user.user.user.id_alumno
    const { id } = useParams();
    const itemid = id;

    const { loading: loadingQuentliPaymentConcept, error: errorQuentliPaymentConcept, data: dataQuentliPaymentConcept, refetch: refetchQuentliPaymentConcept } = useQuery(GET_PAYMENT_CONCEPT, {
        variables: {
            filter: {
                sku: {
                    equals: slugify(`${producto?.name} ${producto?.id}`)
                }
            }
        },
        skip: !producto
    });

    const { loading: loadingQuentliInvoice, error: errorQuentliInvoice, data: dataQuentliInvoice } = useQuery(GET_INVOICE, {
        variables: { 
            filter: { 
                metadata: { 
                    some: { 
                        key: { name: "orderId" }, 
                        value: String(itemid) 
                    }
                } 
                
            } 
        }
    });

    const { loading: loadingQuentliCustomer, error: errorQuentliCustomer, data: dataQuentliCustomer } = useQuery(GET_CUSTOMER, {
        variables: { 
            filter: { 
                OR: [
                { 
                    email: { 
                        equals: user.user.user.CORREO_ELECTRONICO 
                    } 
                }, 
                { username: { equals: String(userid) } } ] 
            }
        },
      });

    const [ createInvoiceQuentliFunction, { data: createInvoiceQuentliData }] = useMutation(CREATE_INVOICE);
    const [ createCustomerQuentliFunction, { data: createCustomerQuentliData }] = useMutation(CREATE_CUSTOMER);
    const [ createPaymentConceptQuentliFunction, { data: createPaymentConceptQuentliData }] = useMutation(CREATE_PAYMENT_CONCEPT);
    const [ createPaymentLinkQuentliFunction, { data: createPaymentLinkQuentliData }] = useMutation(CREATE_PAYMENT_LINK);

    useEffect(() => {
      if (dataQuentliInvoice?.invoices?.length > 0 || dataQuentliInvoice) {
        const invoiceId = dataQuentliInvoice?.invoices[0]?.id || createInvoiceQuentliData?.createInvoice?.invoice?.id;
        if (invoiceId) {
            createPaymentLinkQuentliFunction({ variables: { input: { invoiceId } }})
        }
      }
    }, [dataQuentliInvoice, createInvoiceQuentliData]);

    useEffect(() => {
        if(createPaymentLinkQuentliData?.paymentLink?.url){
            window.location.href = createPaymentLinkQuentliData.paymentLink.url;
        }
      }, [createPaymentLinkQuentliData])

    
    useEffect(() => {
        const executePayment = async () => {
            if(content && content.payment.id !== 4){
            window.location.href = `${global.domain}payments/${content.payment.action}/${itemid}`;
            }
            if(content && content.payment.id == 4 && !loadingQuentliInvoice && !loadingQuentliCustomer && !loadingQuentliPaymentConcept){
                // Revisa si el usuario existe, si no, crearlo.
                if (!loadingQuentliCustomer && !errorQuentliCustomer && dataQuentliCustomer?.customers?.length === 0) {
                    const inputCustomer = {
                        // id: userid,
                        phoneNumber:  user.user.contract.Telefonodom,
                        name: user.user.user.Nombre,
                        username: String(userid),
                        email: user.user.user.CORREO_ELECTRONICO
                    }
                    await createCustomerQuentliFunction({ variables: { input: inputCustomer } })
                }
                // Crearlo sin contrasena y probar.
                // Revisa si la orden ya tiene factura.
                if (dataQuentliInvoice?.invoices?.length > 0) {
                    // Si existe, redirige?.
                    // confirmar respuesta
                    // window.location.href = `https://birmingham-institute.quentli.com/pagar/${dataQuentliInvoice.invoices[0].id}`
                } else {
                    const conceptId = dataQuentliPaymentConcept?.paymentConcepts[0]?.id || createPaymentConceptQuentliData?.data?.id;
                    const customerId = dataQuentliCustomer?.customers[0]?.id || createCustomerQuentliData?.data.id;
                    if (conceptId) {
                        // Si no existe, crea factura.
                        const input = {
                            collectionMethod: 'AUTOMATIC',
                            customerId: String(customerId), // To confirm if unique.
                            dueDate: new Date(),
                            items: [
                                {
                                    conceptId,
                                    quantity: content.order.qty
                                }
                            ],
                            metadata: [
                                {
                                    key: "orderId",
                                    value: String(itemid)

                                }
                            ]
                        }
                        const createInvoice = await createInvoiceQuentliFunction({ variables: { input } });
                        const newInvoiceId = createInvoice.data.createInvoice.invoice.id;
                        // window.location.href = `https://birmingham-institute.quentli.com/pagar/${newInvoiceId}`
                    }
                }

            }
        }
        executePayment();
    }, [content, loadingQuentliInvoice, loadingQuentliCustomer, dataQuentliPaymentConcept, createPaymentConceptQuentliData, loadingQuentliPaymentConcept, dataQuentliInvoice]);
    
    
    useEffect(() => {
        if(producto) {    
            if(dataQuentliPaymentConcept?.paymentConcepts?.length === 0){
                const input = {
                    active: true,
                    amount:  Number(Number(producto.precio).toFixed(2).replace('.','')),
                    description: producto.name,
                    displayName: producto.name, // To confirm if unique.
                    sku: slugify(`${producto.name} ${producto.id}`)
                }
                createPaymentConceptQuentliFunction({ variables: { input }})
            }
        }
    }, [producto, dataQuentliPaymentConcept ])

    useEffect(() => {
        if(producto){
           refetchQuentliPaymentConcept()
        }
      }, [producto]);
            
    useEffect(() => {
      if(content?.order?.cursos_producto && !producto){
        setProducto(content.order.cursos_producto)
      }
    }, [content]);

    useEffect(() => {
        post(
            global.domain+"v3-api/v3/order_data.json",
            { itemid, userid },
            setContent
        )
        sendTrack(userid, window.location.pathname, window.location.hostname, global)
    }, []);
    

    return <> {content === null
        ?
            <Loader/>
        :
        <Row className={'justify-content-center my-5'}>
            <Col lg={6} md={8} sm={12}>
                <Cardstructure title={'Orden #'+content.order.id}>
                    <h4><Moneyformat currency={content.currency} value={content.order.total}/></h4>
                    <p>Estamos procesando su orden..</p>
                    <Loader/>

                </Cardstructure>
            </Col>
        </Row>
        }
    </>

}

