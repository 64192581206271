import {fetching, useAlert, post, sendTrack } from "../Generals"
import React, { useContext, useEffect, useState } from "react"
import Loader from "../Components/Loader";
import logo from "./../logo.png"
import GeneralContext from "../utils/generalContext";
import { useParams } from "react-router";
import dateformat from 'dateformat'

export const Consulta = () => {
    const [,setAlert ]= useAlert();
    const [,setCerrar]=useState(null)
    const [link,setLink]=useState(null)
    const [content,setContent]=useState(null)
    const [first,setFirst]=useState(true)
    const [,setFinish]=useState(null)
    const [ courseState, setCourseState ]=useState(null)
    const { id, cursoid } = useParams();

    const { user, global } = useContext(GeneralContext)

    const loadData = () => {
        let expirationdate= Date.parse(courseState?.curso_alumno.expirate_date)
        let now = new Date()
        let today = Date.parse(dateformat(now, "isoDateTime"))
        if(expirationdate < today ){
            setAlert({alert:'El tiempo de expiración de este curso ha vencido',type:'alert-danger'})
            window.location.href = '/escritorio';
            return;
        }

        let params = {
            userid: user.user.user.id_alumno,
            cursoid,
            itemid: id || null
        }
        let url=global.domain+"api/v1/consultaData/"+global.apicode+'/'
        fetching(url,params,setContent,null)
    }

    useEffect(
        () => {
            const userid = user.user.user.id_alumno
            post(
                global.domain+"v3-api/v3/course_state.json",
                { 
                    cursoid,
                    userid 
                },
                setCourseState
            )

            sendTrack(userid, window.location.pathname, window.location.hostname, global);
        },
        []
    )


    const salirWaitingList = () => {
        let paramsd = {
            userid: user.user.user.id_alumno
        }
        let urld=global.domain+"api/v1/closeOnWaitingList/"+global.apicode
        fetching(urld,paramsd,setCerrar,null)
        setAlert({alert:'Ha salido de la lista de espera',type:'alert-info'})
        window.location.href = '/escritorio';
    }

    const validateToRedirect = () =>{
        setAlert({alert:'La reunión ha finalizado.',type:'alert-info'})
        window.location.href = cursoid ? '/tracking/'+cursoid : '/escritorio';
    }

    const finishmeeting = () => {
        let paramsd = {
            userid:user.user.user.id_alumno
        }
        let urld=global.domain+"api/v1/finishMeeting2/"+global.apicode+'/'
        fetching(urld,paramsd,setFinish,null)
        validateToRedirect()
    }

    const checkStatusMeetingRoom = () => {
        let params = {
            cursoid,
            userid:user.user.user.id_alumno,
            meetingid:content.meetingid
        }
        let url=global.domain+"api/v1/checkStatusMeetingRoom/"+global.apicode+'/'
        fetching(url,params,setLink,null)
    }

    useEffect(loadData,[courseState])

    useEffect(()=>{
        const interval=setInterval(()=>{
            checkStatusMeetingRoom()
        },3500)
        return()=>clearInterval(interval)
    })


    if(content !== null && content.error === 1){
        setAlert({alert:'No tiene sesiones disponibles para consultar.',type:'alert-info'})
        window.location.href = '/escritorio'
    }

    if(first && link !== null){
        setFirst(false)
    }

    if( link !== null && link.terminado === true){
        validateToRedirect()
    }

    return <div>
        {content !== null ?
            <>{link === null || link.url === null ?
                <div className="row justify-content-center">
                    <div className="text-center col-lg-8 col-md-9 col-sm-12">
                        {content.profesoresdisponibles > 0 ?
                            <>
                                <i className="fas fa-spinner fa-spin"></i>
                                <div className="alert alert-info">
                                    <p>En un momento le atenderán.</p>
                                    <p className="m-0">Esperando por el próximo profesor disponible. Por favor, no cierre ni cambie de
                                        ventana.</p>
                                </div>
                                <p>Para cancelar su turno en la lista de espera:</p>
                                <button className={'btn btn-warning'}
                                    onClick={()=>salirWaitingList()}>click aqui
                                </button>
                                <p className="text-justify mt-4">
                                    Si es la primera vez uniéndote a una consulta a través de nuestra plataforma, se te pedirá que
                                    instales la aplicación en tu Computadora. Si no puedes descargar la Aplicación, podrás utilizar la
                                    web App y podrás comenzar la reunión en el mismo explorador.
                                </p>
                                <p className="text-justify">
                                    Si no es la primera vez y ya tienes la aplicación instalada, el programa se abrirá automáticamente
                                    luego de comenzada la reunión.
                                </p>
                                <div id="chat" className="chat"></div>
                            </>
                            :
                            <>
                                <div className="alert alert-warning">
                                    <p className="mb-0">Todos los profesores se encuentran ocupados,
                                sin embargo estas en una lista de espera, en cuanto uno se desocupe te avisaremos. Puedes realizar
                                otras actividades en la plataforma recuerda encender tu sonido pues una campanita te avisará cuando
                                tu profesor este disponible.</p>
                                </div>
                                <p>Para cancelar su turno en la lista de espera
                                <button className={'link'} onClick={()=>salirWaitingList()}>click aqui</button>.</p>
                            </>
                        }
                    </div>
                </div>
                :
                    <div>
                        <div className="row justify-content-center my-5">
                            <div className="col-xl-5 col-6">
                                <div className="card card-people-two">
                                    <div className="card-header">
                                        <div className="pos-absolute a-0">
                                            <img src={logo} alt="Birmingham Institute" />
                                        </div>
                                        <div className="avatar avatar-md">
                                            <div className="bgimgh rounded-circle"
                                                 style={{widht:84, height:84, backgroundImage: 'url('+global.domain+link.profesor.img+')'}}></div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <p className="text-muted">Está siendo atendido por:</p>
                                        <h5 className="card-title ">{link.profesor.nombre}</h5>
                                        <p>El profesor está esperando.</p>
                                        <h5 className="font-weight-light"><a
                                            className="btn btn-primary mt-3" target="_blank" href={link.url}>Entrar
                                            a la asesoría</a>.</h5>
                                        <h5 className="font-weight-light">Ya ha finalizado la reunión? <button
                                            className="link" onClick={()=>finishmeeting()}>Ciérrela aquí.</button>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        :
            <Loader/>}
        </div>
}

