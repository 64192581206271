import 'bootstrap/dist/css/bootstrap.min.css'
import './stylesheets/cassie.css'
import './stylesheets/App.css'
import Loader from './Components/Loader'

import axios from "axios";
import { GeneralContextProvider } from '../src/utils/generalContext'

import React, {useState, useEffect} from 'react'
import noimg from './no-img.jpg'
import SwitchingViews from './Views/Layout/switchingViews'

import { useLoading, get, post} from './Generals';

function App(){
    const [ loading, setLoading ] = useLoading();
    const [ loadingContent, setLoadingContent ] = useState(false);
    const [ main, setMain ] = useState(null);
    const [ contract, setContract ] = useState(null);
    const [ oficina, setOficina ] = useState(false);
    const [ user, setUser ] = useState(JSON.parse(localStorage.getItem('user')));
    const [ modalContent, setModalContent ] = useState({ title:null, content:null })
    const [ modalShow, setModalShow ] = useState(false);

    const global = {
        apicode:'pAiPZ1BNInRc23kq7MtoWsa5FxLXQ9dVzCYSerDfvK6mEwTJgUbG4j0OH8luhy',
        domain :'https://cursos.birmingham-institute.com/'
    }

    const toggleModal = () => setModalShow(!modalShow);

    const getMain = async () => {
        let url=global.domain+"api/v1/getMainData/"+global.apicode
        let res = await axios.get(url);
        setMain(res.data.result)
        setLoading(false);
    }

    useEffect(() => {
        user && post(
                global.domain+"v3-api/v3/contrato.json",
                {no_de_matricula: user.user.no_de_matricula},
                setContract
            )
        user && !oficina && get(
            global.domain+"v3-api/v3/oficina.json",
            {instituto: user.user.INSTITUTO},
            setOficina
        )
        },[]
    );

    useEffect(
        () => {
            main === null && getMain()
        }
        , []);

    return <GeneralContextProvider value={
            {
                modal: {
                    modalShow,
                    toggleModal,
                    modalContent,
                    setModalContent
                },
                loadingContent:{
                    loadingContent,
                    setLoadingContent
                },
                main,
                user: {
                    user,
                    setUser
                },
                global,
                noimg,
                contract,
                oficina
            }
        }>
        { loading ? <Loader /> : <SwitchingViews /> }
    </GeneralContextProvider>
}

export default App
