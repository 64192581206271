import {
    AlbumsOutline,
    AppsOutline,
    ChatboxOutline,
    EllipseOutline,
    HelpBuoyOutline
} from "react-ionicons";

import { Link } from "react-router-dom";
import packageJson from '../../../package.json';
import React, { useContext } from "react";
import GeneralContext from "../../utils/generalContext";

let styles = {
    iconsm:{
        size:"15px"
    },
    iconmd:{
        size:"30px"
    },
    iconlg:{
        size:"50px"
    },
}

const SideMenu = () => {
    const {modal, user} = useContext(GeneralContext)

    const toggleSubmenu = (e) => {
        let parent=e.target.parentNode
        if(parent.classList.contains('show')){
            parent.classList.remove('show')
        }else{
            parent.classList.add('show')
        }
    }

    const renderMenucursos = () => {
        return user?.user?.activeCursos.map((item,index)=>{
            let submenits=[{
                id:0,
                name:'Continuar',
                link:'/tracking/'+item.cursoid,
                isLink:true,
                target:"_self"
            }]
            item.cursos_curso.cursos_recursos.map((itemd,index)=>{
                let content=itemd.html
                submenits.push({
                    id:index+1,
                    name:itemd.nombre,
                    link:() => {
                        modal.setModalContent({title: itemd.nombre, content: content})
                        modal.toggleModal()
                    },
                    isLink:false,
                    isModal:true,
                    target:"_self"
                })
            })
           return {
                id: index,
                icon: <EllipseOutline className={'mr-1'} height={styles.iconsm.size} width={styles.iconsm.size} />,
                name: item.cursos_curso.nombre,
                isLink: false,
                target: "_self",
                submenu: submenits,
                link: ""
            }
        })
    }

    const menu = [
        {
            'id':2,
            'label':'Generales',
            'menu':[{
                id:1,
                icon:<AppsOutline className={'mr-1'} height={styles.iconsm.size} width={styles.iconsm.size} />,
                name:"Escritorio",
                isLink:false,
                target:"_self",
                submenu:false,
                link:"/escritorio"
            }]
        },
        {
            'id':3,
            'label':'Cursos activos',
            'menu':renderMenucursos()
        },
        {
            'id':4,
            label:'Archivos',
            menu:[
                {
                    id:1,
                    icon:<AlbumsOutline className={'mr-1'} height={styles.iconsm.size} width={styles.iconsm.size} />,
                    name:"Cursos Activos",
                    isLink:false,
                    target:"_self",
                    submenu:false,
                    link:"/cursos-activos",
                },
                {
                    id:2,
                    icon:<AlbumsOutline className={'mr-1'} height={styles.iconsm.size} width={styles.iconsm.size} />,
                    name:"Cursos Completados",
                    isLink:false,
                    target:"_self",
                    submenu:false,
                    link:"/cursos-completados",
                },
            ]
        },
        {
            'id':5,
            'label':'Consulta',
            'menu':[{
                id:1,
                icon:<ChatboxOutline className={'mr-1'} height={styles.iconsm.size} width={styles.iconsm.size} />,
                name:"Consultas",
                isLink:true,
                submenu:[
                    {
                        id:1,
                        name:'Con el profesor',
                        link:"/escoger-consulta",
                        isLink:false,
                        target:"_self"
                    },
                    {
                        id:2,
                        name:'Historial de consultas',
                        link:"/historial-de-consultas",
                        isLink:false,
                        target:"_self"
                    },
                    {
                        id:3,
                        name:'Paquetes de consultas',
                        link:"/paquetes-de-consulta",
                        isLink:false,
                        target:"_self"
                    }
                ],
                target:"_self",
                link:"/consulta"
            }]
        },
        {
            'id':6,
            'label':'Soporte y ayuda',
            'menu':[{
                id:1,
                icon:<HelpBuoyOutline className={'mr-1'} height={styles.iconsm.size} width={styles.iconsm.size} />,
                name:"Faqs e instructivos",
                isLink:false,
                target:"_self",
                link:"",
                submenu:[
                    {
                        id:1,
                        name:'Instructivos',
                        link:"/instructivos",
                        isLink:false,
                        target:"_self"
                    },
                    {
                        id:2,
                        name:'Preguntas Frecuentes',
                        link:"/preguntas-frecuentes",
                        isLink:false,
                        target:"_self"
                    }
                ]
            },
                {
                    id:2,
                    icon:<ChatboxOutline className={'mr-1'} height={styles.iconsm.size} width={styles.iconsm.size} />,
                    name:"Soporte en línea",
                    isLink:true,
                    target:"_blank",
                    link:"https://api.whatsapp.com/send?phone=52113319309524&text=Solicito%20ayuda"
                }
            ]
        },
        {
            'id':7,
            'label':'Birminham v'+packageJson.version,
            'menu':[]
        }
    ]

    return (
        <ul className={'nav nav-sidebar'}>
            {menu.map( item =>
                <div key={item.id}>
                    <li key={'menu-label'+item.id} className={'nav-label'}>
                        <label className={'content-label'}>{item.label}</label>
                    </li>
                    {
                        item.menu.map( menui =>
                            <li key={'sub-menu'+menui.id} className={'nav-item'}>
                                {!menui.submenu ?
                                    <>
                                        {menui.isLink ? <a className={'nav-link'} target={menui.target} href={menui.link}>{menui.icon} {menui.name}</a> : <Link className="nav-link" to={menui.link}>{menui.icon} {menui.name}</Link>}
                                    </>
                                    :
                                    <>
                                        <button onClick={(e)=>toggleSubmenu(e)} className={'nav-link'}>{menui.icon} {menui.name}</button>
                                        {menui?.submenu?.length > 0 ? <nav className={'nav nav-sub'}>
                                            {menui.submenu.map(submenu=> {
                                                return <span key={'subm'+item.id+submenu.id}>{ 
                                                    submenu.isLink ? 
                                                    <a className={'nav-sub-link'} target={submenu.target} href={submenu.link}>{submenu.name}</a> 
                                                    : 
                                                        <>
                                                            { !submenu.isModal ?
                                                                <Link to={submenu.link} className={'nav-sub-link'}>{submenu.name}</Link>
                                                            :
                                                                <button onClick={submenu.link} className={'nav-sub-link'}>{submenu.name}</button>
                                                            } 
                                                        </>
                                                   
                                                    }
                                                </span>
                                            })}
                                        </nav> :null}
                                    </>
                                }
                            </li>
                        )
                    }
                </div>
            )}
        </ul>
    )
}

export default SideMenu
