import { gql } from '@apollo/client';

export const GET_INVOICE = gql`
  query GetInvoices($filter: InvoiceFilter) {
    invoices (filter: $filter){
      id
      invoiceNumber
      status
      metadata {
        key
        value
      }
    }
  }
`;

export const GET_CUSTOMER= gql`
  query GetCustomers($filter: CustomerFilter) {
    customers (filter: $filter){
      id,
      username,
      email
    }
  }
`;

export const CREATE_PAYMENT_LINK = gql`
  mutation PaymentLink($input: PaymentLinkInput!) {
    paymentLink(input: $input) {
      url
    }
  }
`;

export const GET_PAYMENT_CONCEPT = gql`
  query GetPaymentConcepts($filter: PaymentConceptFilter) {
    paymentConcepts (filter: $filter) {
      id
      amount
      currency
      description
      sku
    }
  }
`;

export const CREATE_INVOICE = gql `
    mutation CreateInvoice($input: CreateInvoiceInput!) {
        createInvoice(input: $input) {
            invoice {
                id
                customer {
                    id
                    name
                }
                items {
                    id
                    amount
                    description
                }
            }
        }
    }
`;

export const CREATE_PAYMENT_CONCEPT = gql `
    mutation CreatePaymentConcept($input: CreatePaymentConceptInput!) {
        createPaymentConcept(input: $input) {
            paymentConcept {
                id
                active
                amount
                currency
                description
                displayName
                sku
            }
        }
    }
`;

export const CREATE_CUSTOMER = gql `
  mutation CreateCus($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      ...on CreateCustomerSuccess {
        customer {
          id
          name
          phoneNumber
          email
        }
      }
    }
  }
`;