import React, {useEffect, useState, useContext} from "react"
import Loader from './../Components/Loader'
import { Moneyformat, fetching, post, sendTrack } from "../Generals"
import { useParams } from "react-router-dom";
import { Row,Col } from "react-bootstrap"
import GeneralContext from "../utils/generalContext";

export const Comprar = (props) => {
    const { id } = useParams();
    const { global, user } = useContext(GeneralContext)
    const userid = user.user.user.id_alumno

    const [ content, setContent ] = useState(null)
    const [ currency, setCurrency ] = useState(null)
    const [ total, setTotal ] = useState(null)
    const [ precio, setPrecio ] = useState(null)
    const [ formaDePago, setFormaDePago ] = useState(null)
    const [ formasDePago, setFormasDePago ] = useState(null)
    const [ qty, setQty ] = useState(1)
    const [ razon_social, setRazonSocial ] = useState(null)
    const [ rfc, setRFC ] = useState(null)
    const [ alert, setAlert ] = useState()
    const [ response, setResponse ] = useState(null)
    const [ facturaElectronica,setFacturaElectronica ] = useState(false)

    const loadContent = () => {
        let params = {
            itemid: id,
            userid
        }
        let url=global.domain+"api/v1/comprarData/"+global.apicode+'/'
        fetching(url,params,setContent,null)
    }

    useEffect(() => {
        sendTrack(userid, window.location.pathname, window.location.hostname, global);
        loadContent()
    }, [])

    useEffect(() => {
        if(content){
            const allowed_payments_ids = content?.payemntsIds.map(pa => pa.paymentid)
            const allowed_payments_ids_by_office = content?.payemntsIdsOficinas.map(pa => pa.paymentid)
            const formas_pago_allowed = content?.formas_pago.filter(pa => (pa.id !== 5 && allowed_payments_ids.includes(pa.appid) || (pa.id === 5 && allowed_payments_ids_by_office.includes(pa.appid))))
            setFormasDePago(formas_pago_allowed)
            setFormaDePago(formas_pago_allowed[0].id)
        }
    }, [content]);

    if(currency === null && content !==null){
        setCurrency(content.currencies[0])
    }

    const changeQty = (val) =>  {
        setQty(val)
        setTotal(val*precio)
    }

    if(currency !== null && precio == null){
        let prec=content.producto.precio
        setPrecio(prec)
        setTotal(prec*qty)
    }

    const submitOrder = () => {
        if(facturaElectronica && (rfc == null && razon_social == null)){
            setAlert('Los campos RFC y Razon Social no pueden estar vacíos.')
            return 
        }
        post(
            global.domain+"v3-api/v3/open_order.json",
            {
                productid: id,
                qty,
                userid,
                total,
                facturaelectronica: facturaElectronica,
                rfc,
                razon_social,
                formadepago: formaDePago
            },
            setResponse
        )
    }

    useEffect(() => {
        if(response?.orderId){
            window.location.href = `/pagar-orden/${response.orderId}`
        }
        if(response?.error){
            setAlert(response.error)
        }
    }, [response])

    return <>
        {content === null || currency === null ? <Loader/> :
            <Row className={'justify-content-center py-5'}>
                <Col xs={12} sm={10} md={8}>
                    <div className="card rounded-0">
                        <div className="card-header">
                            <h5 className="m-0 font-weight-light">Estas comprando el
                                producto <strong>{content.producto.name}</strong> <span
                                    className="float-right"><Moneyformat value={content.producto.precio} currency={currency} /></span></h5>
                        </div>
                        <div className="card-body">
                            {alert && <div className={'alert alert-danger'}>{alert}</div>}
                            <label htmlFor="qty">Cantidad</label>
                            <div className="input-group mb-3  input-group-lg">
                                <input className={'form-control'} type="number" defaultValue={qty} onChange={(e)=>changeQty(e.target.value)}/>
                            </div>
                            <h4 className="font-weight-light">Total: <strong
                                    className="formatted_total text-success"><Moneyformat value={total} currency={currency} /></strong></h4>
                            <div className="row border-top py-4 mt-4">
                                <div className="col">
                                    <label>Formas de pago</label>
                                    <select className="form-control form-control-lg" 
                                        name="formadepago" 
                                        defaultValue={formaDePago}
                                        onChange={e=>setFormaDePago(e.target.value)}
                                    >
                                        {formasDePago?.map(item=><option value={item.id}>{item.name}</option>)}
                                    </select>
                                </div>
                                <div className="col">
                                    <label className={''}>Requiere factura electrónica</label>
                                    <select value={facturaElectronica} onChange={e=>setFacturaElectronica(e.target.value)}
                                            className="form-control form-control-lg facturaelectronica"
                                            name="facturaelectronica">
                                        <option value={false}>No</option>
                                        <option value={true}>Si</option>
                                    </select>
                                </div>
                            </div>
                            {facturaElectronica === 'true' ?
                                <div className="row electricaspacefields">
                                    <div className="col">
                                        <label>RFC <i className="fa fas fav fa-asterisk text-danger"></i></label>
                                        <input type="text" required={true} onChange={(e) => setRFC(e.target.value)} className="form-control electricalfield" name="rfc"/>
                                    </div>
                                    <div className="col">
                                        <label>Razón Social <i className="fa fas fav fa-asterisk text-danger"></i></label>
                                        <input type="text" required={true} onChange={(e) => setRazonSocial(e.target.value)} className="form-control electricalfield" name="razon_social"/>
                                    </div>
                                </div>
                            : null}
                            <div className="row mt-3">
                                <div className="col">
                                    <button
                                        className={'btn btn-info'}
                                        onClick={()=>submitOrder()}
                                    >Procesar orden</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        }
        </>
}

